<template>
  <div class="headerBox">
    <div class="headerBottom">
      <el-row>
        <el-col :span="24">
          <el-row>
            <el-col :span="4">
              <router-link tag="li" to="/index" style="width:190px;">
                <div class="homeImgBox">
                  <img :src="logoUrlGetter" class="homeImg" />
                </div>
              </router-link>
            </el-col>
            <el-col :span="15">
              <div class="search">
                <form>
                  <input type="text" v-model="searchText" class="searchInput" placeholder="请输入您想要搜索的关键词" />
                  <input type="submit" class="searchSubmit" value="搜索" @click.prevent="goSearch()" />
                </form>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="hotTel">
                <img src="../../../assets/images/homePage/tel.png" alt="" width="40px"/>
                <div class="hotTelContent">
                  <p>全国服务热线</p>
                  <p class="tel">0531-80992869</p>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4">
              <!-- style="width:14%" -->
              <div class="allProduction" @mouseenter="showCategory()" @mouseleave="hideCategory()">
                <span class="allProductionTitle">全部商品分类</span>
              </div>
            </el-col>
            <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/index">
                <div class="com">
                  <span class="comtext">首页</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/ResourceDocuments">
                <div class="com">
                  <span class="comtext">现货销售</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/purchaseDocument">
                <div class="com">
                  <span class="comtext">现货采购</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/productionSell">
                <div class="com">
                  <span class="comtext">产品预售</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/mall">
                <div class="com">
                  <span class="comtext">竞价交易</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2" class="newDefineWidth" v-if="isShowBaise">
              <router-link tag="li" to="/basisTrading">
                <div class="com">
                  <span class="comtext">基差交易</span>
                </div>
              </router-link>
            </el-col>
             <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/supplyDemandInfo">
                <div class="com">
                  <span class="comtext">供求信息</span>
                </div>
              </router-link>
            </el-col>
            <!-- <el-col :span="2" class="newDefineWidth">
              <router-link tag="li" to="/financial">
                <div class="com">
                  <span class="comtext">金融结算</span>
                </div>
              </router-link>
            </el-col> -->
            <el-col :span="2">
              <router-link tag="li" to="/news">
                <div class="com">
                  <span class="comtext">资讯</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="2">
              <router-link tag="li" :to="{ name: 'helpCenter'}">
                <div class="com">
                   <el-dropdown @command="handleCommand" trigger="hover" placement="bottom">
                      <span class="el-dropdown-link comtext">
                        帮助中心<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                    <!-- <span class="comtext">帮助中心</span> -->
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="a">平台规则</el-dropdown-item>
                      <el-dropdown-item command="b">交收服务</el-dropdown-item>
                      <el-dropdown-item command="e">合作质检机构</el-dropdown-item>
                      <el-dropdown-item command="d">常见问题指引</el-dropdown-item>
                      <el-dropdown-item command="f">联系我们</el-dropdown-item>
                       <el-dropdown-item command="c">下载中心</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- <div class="category" @mouseenter="showCategory()" @mouseleave="hideCategory()">
      <category v-show="isShowCategory" />
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import category from "../category/category";
export default {
  data() {
    return {
      isShowCategory: false,
      searchText: null,
      isShowBaise: window.g.isShowBaise,
    };
  },
  computed: {
    ...mapGetters(["searchTextGetter", "logoUrlGetter"])
  },
  watch: {
    searchTextGetter: {
      immediate: true,
      handler(val, oldVal) {
        this.searchText = val;
      }
    }
  },
  components: {
    // category
  },
  mounted() {
    this.$store.commit("changeIsIndex", true);
  },
  methods: {
    showCategory() {
      this.isShowCategory = true;
    },
    hideCategory() {
      this.isShowCategory = false;
    },
    goSearch() {
      this.$store.commit("setSearchText", this.searchText);
      this.$router.push({
        name: "ResourceDocuments"
      });
    },
    handleCommand(e){
      if(e =='d'){
        protocolFwd.param_getContentTitleList.param = {
          columnId: window.c.userHelp,
          page: 0,
          size: 10,
        };
        http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit('setSelectHelpName',window.c.userHelp)
            this.$router.push({
              name:'helpCenter',
              params:{
                id: value.content[0].id
              }
            })
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }else if(e =='b'){
        protocolFwd.param_getContentTitleList.param = {
          columnId: window.c.delevService,
          page: 0,
          size: 10,
        };
        http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit('setSelectHelpName',window.c.delevService)
            this.$router.push({
              name:'helpCenter',
              params:{
                id: value.content[0].id
              }
            })
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }else if(e =='c'){
        this.$EL_MESSAGE('请点击帮助中心查看需要下载的文件');
      }else if(e =='e'){
        protocolFwd.param_getContentTitleList.param = {
          columnId: window.c.cooperation,
          page: 0,
          size: 10,
        };
        http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit('setSelectHelpName',window.c.cooperation)
            this.$router.push({
              name:'helpCenter',
              params:{
                id: value.content[0].id
              }
            })
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }else if(e =='f'){
        protocolFwd.param_getContentTitleList.param = {
          columnId: window.c.contactUs,
          page: 0,
          size: 10,
        };
        http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit('setSelectHelpName',window.c.contactUs)
            this.$router.push({
              name:'helpCenter',
              params:{
                id: value.content[0].id
              }
            })
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }else{
        protocolFwd.param_getContentTitleList.param.page = 0;
        protocolFwd.param_getContentTitleList.param.size = 10;
        protocolFwd.param_getContentTitleList.param.columnId =
          window.c.plateRules;
        http.getRes(protocolFwd.param_getContentTitleList).then((response) => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit('setSelectHelpName',window.c.plateRules)
            this.$router.push({
                name:'helpCenter',
                params:{
                  id: value.content[0].id
                }
              })
          } else {
            this.$EL_MESSAGE(message);
          }
        });
      }
    
    },
  }
};
</script>

<style lang="scss" scoped>
.headerBox {
  width: 100%;
  background-color: $navBgColor;
}
.headerBottom {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 0 auto;
}
.headerBottom .homeImgBox {
  height: 70px;
  margin: 15px 0 5px 0;
  text-align: center;
  cursor: pointer;
}
.headerBottom .homeImgBox p {
  font-size: 20px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}
.headerBottom .homeImgBox img {
  max-width: 350px;
  max-height: 100%;
}
.headerBottom .allProduction {
  // width: 190px;
  height: 45px;
  text-align: center;
  color:#333;
  // color: #ffffff;
  // background-color: $commonThemeColor;
  cursor: pointer;
}
.headerBottom .allProduction .allProductionTitle {
  font-size: 16px;
  display: inline-block;
  margin-top:18px;
}
.headerBottom .com {
  margin-top: 18px;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.headerBottom .comtext {
  font-size: 16px;
  color: #333333;
}
.headerBottom .search {
  position: relative;
  width: 660px;
  margin: 25px auto 10px;
  // left: -70px;
  overflow: hidden;
  box-sizing: border-box;
}
.headerBottom .hotTel {
  position: relative;
  text-align:right;
  top:25px;
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
.hotTel img{
  margin-right:5px;
}
.hotTelContent{
  color:#cf4640;
}
.hotTelContent .tel{
  font-size:20px;
  font-style:italic;
  font-weight: bold;
}
.headerBottom .search .searchInput {
  float: left;
  width: 85%;
  text-indent: 15px;
  height: 40px;
  line-height: 40px;
  border: 0;
  border: 2px solid $commonThemeColor;
  border-radius:30px 0 0 30px;
  box-sizing: border-box;
  outline: none;
}
.headerBottom .search .searchSubmit {
  float:right;
  width: 15%;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  background-color: $commonThemeColor;
  border: 0;
  border-radius:0 30px 30px 0;
  box-sizing: border-box;
  cursor: pointer;
}
.category {
  position: relative;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 0 auto;
}
.router-link-active span.comtext {
  color: $commonThemeColor;
  border-bottom: 2px solid $commonThemeColor;
}
.newDefineWidth{
  width:11%;
}
</style>
