<template>
  <div class="pageContent">
    <!-- <searchHeader /> -->
    <div class="newsDetails">
      <i class="el-icon-arrow-left colors" @click="goBack()"></i>
      <span class="goback" @click="goBack()">返回</span>
      <p class="newsTitle">{{ newsDetails.title }}</p>
      <div class="newsContent" v-html="newsDetails.content"></div>
    </div>
  </div>
</template>
<script>
import searchHeader from "../../base/header/searchHeader";
export default {
  data() {
    return {
      newsDetails: {
        title: null,
        titleImg: null,
        subtitle: null,
        source: null,
        content: null
      }
    };
  },
  components: {
    searchHeader
  },
  watch: {
    $route(){
      this.getNewsDetails(this.$route.params.id);
    }
  },
  mounted() {
    console.log(document.getElementsByClassName('pageContent')[0].scrollTop, '父盒子高度')
    document.getElementsByClassName('el-main')[0].scrollTop = 0
    document.getElementsByClassName('pageContent')[0].scrollTop = 0
    this.getNewsDetails(this.$route.params.id);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getNewsDetails(id) {
      protocolFwd.param_getContentDetails.param.id = id;
      http.getRes(protocolFwd.param_getContentDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.newsDetails = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.newsDetails {
  position: relative;
  padding: 15px;
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  // min-height: 80vh;
  margin: 10px auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.newsDetails .goback {
  position: absolute;
  color: $commonThemeColor;
  cursor: pointer;
}
.colors{
  color: $commonThemeColor;
  cursor: pointer;
}
.newsDetails .newsTitle {
  font-size: 18px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
}
.newsDetails .newsImage {
  width: 600px;
  margin: 10px auto;
}
.newsDetails .newsImage img {
  width: 100%;
}
.newsDetails .newsSource,
.newsDetails .newsDesc,
.newsDetails .newsContent {
  line-height: 25px;
  color: #9999a2;
}
</style>



